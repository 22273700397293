@import '../../global/styles.scss';

.Markdown {
  display: block;

  &_bedsheet {
    max-width: 1000px;
  }
}

.Markdown__blockquote {
  display: block;
  padding: $spacingSm $spacingLg;
  margin: $spacingMd;
  border-radius: $radiusMd;
  background-color: bisque;
}

.Markdown__img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
